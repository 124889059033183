<template>
  <v-row>
    <v-col cols="12" md="4">
      <p>Loading...</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push('/login')
    }else{
      this.$router.push('/dashboard')
    }
  },
}
</script>
